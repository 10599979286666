import Cookies from 'js-cookie';
import { isEqual } from 'lodash';

import { useEffect, useRef } from 'react';
import { APP_REGION } from 'utils/constants';

export enum AppRegion {
  SG = 'sg',
  MY = 'my',
  PH = 'ph',
  TH = 'th',
  VN = 'vn',
}

const mappingRegionAndDefaultLang = {
  [AppRegion.SG]: 'en',
  [AppRegion.PH]: 'ph',
  [AppRegion.MY]: 'ms',
  [AppRegion.TH]: 'th',
  [AppRegion.VN]: 'vn',
};

export function useAppRegion() {
  // Normal calculation so no need use memo!
  let region = AppRegion.TH;

  switch (APP_REGION) {
    case 'ph': {
      region = AppRegion.PH;
      break;
    }
    case 'sg': {
      region = AppRegion.SG;
      break;
    }
    case 'my': {
      region = AppRegion.MY;
      break;
    }
    case 'th': {
      region = AppRegion.TH;
      break;
    }
    case 'vn': {
      region = AppRegion.VN;
      break;
    }
  }
  return {
    region,
    regionDefaultLang: mappingRegionAndDefaultLang[region],
  };
}

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useClickTracker = (time = 3, within = 300000) => {
  const handleClick = ({ onSuccess }: { onSuccess: () => void }) => {
    const now = Date.now();
    let clicks: number[] = Cookies.get('clicks') ? JSON.parse(Cookies.get('clicks') || '') : [];

    clicks = clicks.filter((click) => now - click < within);

    clicks.push(now);
    Cookies.set('clicks', JSON.stringify(clicks), { expires: new Date(new Date().getTime() + within) });

    if (clicks.length >= time) {
      onSuccess();
    }
  };

  return handleClick;
};

export const useDeepCompareEffect = (callback: any, dependencies: any) => {
  const useDeepCompareMemoize = (value: any) => {
    const ref = useRef();
    if (!isEqual(value, ref.current)) {
      ref.current = value;
    }

    return ref.current;
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
};
