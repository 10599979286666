import { isVN } from 'utils/constants';

enum CartStatus {
  Pending = 0,
  WaitingForPayment,
  CartExpired,
  Completed,
  Failed,
  Abandoned,
  PendingForVerification,
  Canceled,
}

export const CART_STATUS = isVN
  ? [CartStatus.Pending, CartStatus.PendingForVerification, CartStatus.Completed]
  : [CartStatus.Pending, CartStatus.PendingForVerification, CartStatus.Failed, CartStatus.Completed];

export const getAdvancedFilterCartStatuses = () => {
  return CART_STATUS.map((o) => {
    return {
      id: o.toString(),
      name: cartStatusToDisplay(o),
    };
  });
};

export const cartStatusToDisplay = (status: CartStatus): string => {
  switch (status) {
    case CartStatus.Pending:
    case CartStatus.WaitingForPayment:
      return 'pending_checkout';
    case CartStatus.CartExpired:
      return 'expired';
    case CartStatus.Failed:
      return 'failed';
    case CartStatus.Abandoned:
      return 'abandoned_by_user';
    case CartStatus.PendingForVerification:
      return 'pending_verification';
    case CartStatus.Canceled:
      return 'canceled';
    case CartStatus.Completed:
      return 'paid';
    default:
      return 'UNKNOWN STATUS';
  }
};

export default CartStatus;
